html {
  height: 100%;
  width: 100%;
  scroll-behavior: smooth;
}

body {
  height: 100%;
  width: 100%;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--main-text-color);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  outline: none;
  text-decoration: none;
  color: var(--accent-color);
}

a:link {
}

a:visited {
}

a:focus {
}

a:hover {
}

a:active {
}

a.button {
  color: var(--secondary-color);
  border: 1px solid var(--secondary-color);
  padding: 10px 20px;
  border-radius: 2px;
  text-transform: uppercase;
  font-size: 1rem;
}

a.button:hover {
  color: var(--secondary-color-selected);
  border: 1px solid var(--secondary-color-selected);
  background-color: var(--secondary-color-transparent);
}

hr {
  margin: 30px auto;
  width: 80px!important;
  border: 0;
  border-top: 1px solid var(--secondary-color);
}

footer {
  background-color: var(--main-color);
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--secondary-color);
}

.bg0 {
  background-image: url("./res/images/backgrounds/0.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.bg1 {
  background-image: url("./res/images/backgrounds/1.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.bg2 {
  background-image: url("./res/images/backgrounds/2.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.bg3 {
  background-image: url("./res/images/backgrounds/3.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.bg4 {
  background-image: url("./res/images/backgrounds/4.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.bg5 {
  background-image: url("./res/images/backgrounds/5.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.bg6 {
  background-image: url("./res/images/backgrounds/6.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.bg7 {
  background-image: url("./res/images/backgrounds/7.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.bg8 {
  background-image: url("./res/images/backgrounds/8.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.bg9 {
  background-image: url("./res/images/backgrounds/9.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.bg10 {
  background-image: url("./res/images/backgrounds/10.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.bg11 {
  background-image: url("./res/images/backgrounds/11.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.bg12 {
  background-image: url("./res/images/backgrounds/12.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.bg13 {
  background-image: url("./res/images/backgrounds/13.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.bg14 {
  background-image: url("./res/images/backgrounds/14.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.bg15 {
  background-image: url("./res/images/backgrounds/15.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.bg16 {
  background-image: url("./res/images/backgrounds/16.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.bg17 {
  background-image: url("./res/images/backgrounds/17.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.bg18 {
  background-image: url("./res/images/backgrounds/18.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.bg19 {
  background-image: url("./res/images/backgrounds/19.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.bg20 {
  background-image: url("./res/images/backgrounds/20.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.bg21 {
  background-image: url("./res/images/backgrounds/21.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.hero {
  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  min-height: 500px;
  margin: 0;
  padding: 0;
  text-align: center;
}

@media (hover: none) {
  .hero {
    /* Disable the parallax scrolling effect */
    background-attachment: initial;
  }
}

.hero > div {
  background-color: #333d51e8;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #f9fbff;
}

.hero-logo {
  height: 40vmin;
  pointer-events: none;
  max-width: 50%;
}

section:not(.hero) {
  background-color: var(--main-bg-color);
  padding: 5%;
  text-align: justify;
}

section > h1 {
  text-align: center;
}

section.divider {
  background-color: var(--secondary-color);
  height: 1.5px;
  padding: 0;
}

.horizontal-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  align-content: stretch;
  gap: 20px 30px;
  margin-top: 30px;
}

.icon-item-vertical {
  width: 30%;
}

@media only screen and (max-width: 1000px) {
  .icon-item-vertical {
    width: 40%;
  }
}

@media only screen and (max-width: 700px) {
  .icon-item-vertical {
    width: 90%;
  }
}

.filter-accent-color {
  filter: invert(12%) sepia(75%) saturate(7449%) hue-rotate(11deg) brightness(88%) contrast(119%);
}

.icon-item-vertical-icon {
  display: block;
  width: 80px;
  height: 80px;
  margin: 0 auto;
}

.icon-item-vertical-header {
  text-align: center;
  font-weight: bold;
  color: var(--main-color);
}

.icon-item-vertical-description {
  text-align: center;
  color: var(--secondary-text-color);
}