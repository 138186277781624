:root {

    /* Colors */
    --main-color: #333d51;
    --main-color-selected: #535D71;
    --secondary-color: #bedaff;
    --secondary-color-selected: #ceeaff;
    --secondary-color-transparent: #bedaff33;
    --main-bg-color: #f9fbff;
    --main-text-contrast-color: #f9fbff;
    --main-text-color: #111111;
    --main-text-disabled-color: #11111199;
    --secondary-text-color: #888888;
    --accent-color: #E10000;
  
    /* Style */
    --disabled-alpha: 0.6;
    --shadow-color: #00000033;
  }